import Cookies from "universal-cookie";
import axios from "axios";


export const pricing = {
    PREMIUM: 250,
    BUSINESS: 300,
    VAN: 350,
    MINIBUS: 550
}

class ApiClient {
    backendBaseUrl = 'https://api.croatia-airport-transfer.com';
    appType = 'web';
    version = 'v1';

  sendEmail = async (data, subject) => {
    try {
      const response = await axios.post('https://express-mail-f39c259b022c.herokuapp.com/send-email',
        {
          from: 'info@croatia-airport-transfer.com',
          to: 'info@croatia-airport-transfer.com',
          subject,
          text: data,
        },);

            return response.status === 202;
        } catch (error) {
            console.error('Error sending email:', error);
            return null;
        }
    };

    fetchLocationList = async () => {
        return await fetch(`${this.backendBaseUrl}/${this.appType}/${this.version}/location`)
            .then(res => res.json());
    };

    loginUser = async (username, password) => {
        return await fetch(`${this.backendBaseUrl}/${this.appType}/${this.version}/auth/login/user`, {
            headers: {
                'username': username,
                'password': password,
            }
        }).then(res => res.json());
    };

    deleteTransferBooking = async (transferBookingId, cancellationToken) => {
        const transferData = {
            "data": {
                "id": transferBookingId,
                "type": "transfer-booking",
            }
        };

        return await fetch(`${this.backendBaseUrl}/${this.appType}/${this.version}/transfer-booking/${transferBookingId}`, {
            headers: {
                'cancellationToken': cancellationToken,
                'Content-Type': 'application/json'
            },
            method: 'DELETE',
            body: JSON.stringify(transferData)
        }).then(res => res.json());
    };

    getUserById = async (userId) => {
        return await fetch(`${this.backendBaseUrl}/${this.appType}/${this.version}/user/${userId}`).then(res => res.json());
    };

    getClientMessages = (clientId) => {
        return fetch(`${this.backendBaseUrl}/${this.appType}/${this.version}/message?filter[clientId]=${clientId}`).then(res => res.json());
    };

    sendContactUsEmail = async (email, name, message) => {
        return await this.sendEmail(
          `
            Name: ${name}
            Email: ${email}
            Message: ${message}
         `, 'Contact request');
    };

    getExcursions = async () => {
        return await fetch(`${this.backendBaseUrl}/${this.appType}/${this.version}/excursion?include=fromLocation,toLocation`).then(res => res.json());
    };

    getTransferBookingById = (transferBookingId) => {
        return fetch(`${this.backendBaseUrl}/${this.appType}/${this.version}/transfer-booking/${transferBookingId}`).then(res => res.json());
    };


  getPriceBetweenLocations = async (fromId, toId) => {

    return new Promise((resolve, reject) => {

      new window.google.maps.DistanceMatrixService().getDistanceMatrix(
        {
          origins: [fromId],
          destinations: [toId],
          travelMode: 'DRIVING',
        }, (response, status) => {
          if (status === 'OK') {
            const distance = Math.round(response.rows[0].elements[0].distance.value/1000);
            const duration = response.rows[0].elements[0].duration.value / 60;
            resolve({ distance, duration });
          } else {
            reject(new Error(`Distance matrix request failed with status: ${status}`));
          }
        }
      );
    });
  };

    getUserReservations = async (userId) => {
        return await fetch(`${this.backendBaseUrl}/${this.appType}/${this.version}/transfer-booking?filter[userId]=${userId}`).then(res => res.json());
    };

    registerUser = async (
        email,
        password,
        firstName,
        lastName,
        phone,
    ) => {
        const userData = {
            "data": {
                "type": "user",
                "attributes": {
                    "email": email,
                    "firstName": firstName,
                    "lastName": lastName,
                    "phone": phone,
                    "password": password
                }
            }
        };
        return await fetch(
            `${this.backendBaseUrl}/${this.appType}/${this.version}/user`,
            {
                method: 'POST',
                body: JSON.stringify(userData)
            }
        ).then(res => res.json());
    };

    signRbaTransaction = async (orderId, amount) => {
        const data = {
            "data": {
                "type": "rba-transaction",
                "attributes": {
                    "orderId": orderId,
                    "amount": amount,
                }
            }
        };
        return await fetch(
            `${this.backendBaseUrl}/${this.appType}/${this.version}/payment/rba/transaction-signing`,
            {
                method: 'POST',
                body: JSON.stringify(data)
            }
        ).then(res => res.json());
    };

  createTransferBooking = async (
    from,
    to,
    dateTime,
    returnDateTime,
    pickUpAddressOrFlightNumber,
    dropOffAddress,
    message,
    passengers,
    bags,
    firstName,
    lastName,
    email,
    phone,
    price,
    stops,
    vehicleType,
    paidBy,
    infantSeat,
    childSeat,
    boosterSeat,
    bicycle,
    wheelchair,
    extraStop,
  ) => {
    return await this.sendEmail(`
    First Name: ${firstName}
    Last Name: ${lastName}
    Email: ${email}
    Phone: ${phone}
    Transfer Date: ${dateTime}
    From Location: ${from}
    Pick-Up Address or Flight Number: ${pickUpAddressOrFlightNumber}
    To Location: ${to}
    Drop-Off Address: ${dropOffAddress}
    Vehicle Type: ${vehicleType}
    Price: ${price/100}
    Passengers: ${passengers}
    Bicycle: ${bicycle}
    Booster Seat: ${boosterSeat}
    Child Seat: ${childSeat}
    Extra Stop: ${extraStop}
    Infant Seat: ${infantSeat}
    Paid By: ${paidBy}
    Stops: ${stops}
    Wheelchair: ${wheelchair}
    Message: ${message}
    Return Date: ${returnDateTime}
  `, 'Booking details');
  };

    updateTransferBooking = async (
        id,
        from,
        to,
        dateTime,
        returnDateTime,
        pickUpAddressOrFlightNumber,
        dropOffAddress,
        message,
        passengers,
        bags,
        firstName,
        lastName,
        email,
        phone,
        price,
        stops,
        vehicleType,
        paidBy,
        infantSeat,
        childSeat,
        boosterSeat,
        bicycle,
        wheelchair,
        extraStop,
    ) => {
        const cookie = new Cookies();
        const userId = cookie.get('userId');
        let user = [];
        if (userId) {
            user = [{"type": "user", "id": userId}];
        }
        const transferData = {
            "data": {
                "id": id,
                "type": "transfer-booking",
                "attributes": {
                    "fromLocation": from,
                    "toLocation": to,
                    "transferDate": dateTime,
                    "returnDate": returnDateTime,
                    "pickUpAddressOrFlightNumber": pickUpAddressOrFlightNumber,
                    "dropOffAddress": dropOffAddress,
                    "message": message,
                    "passengers": passengers,
                    "bags": bags,
                    "firstName": firstName,
                    "lastName": lastName,
                    "email": email,
                    "phone": phone,
                    "price": parseInt(price, 10),
                    "stops": stops,
                    "vehicleType": vehicleType,
                    "paidBy": paidBy,
                    "infantSeat": infantSeat,
                    "childSeat": childSeat,
                    "boosterSeat": boosterSeat,
                    "bicycle": bicycle,
                    "wheelchair": wheelchair,
                    "extraStop": extraStop,
                },
                "relationships": {
                    "user": {
                        "data": user
                    }
                }
            }
        };
        return await fetch(
            `${this.backendBaseUrl}/${this.appType}/${this.version}/transfer-booking/${id}`,
            {
                method: 'PUT',
                body: JSON.stringify(transferData)
            }
        ).then(res => res.json());
    };
}

export default ApiClient;
