import React from "react";
import { withRouter } from "../../router/ComponentWithRouterProp";
import { LanguageContext } from "../../context/LanguageContext";
import { translations } from "../../translation/Translations";
import Loader from "../general/Loader";

class SubmitButton extends React.Component {
    tabs = {
        "1": 'airport',
        "2": 'transfer',
        "3": 'excursion',
    };

    state = {
        loading: false,
    };

    componentDidMount() {
        // Define the tracking function on window to make it globally accessible
        window.gtag_report_conversion = function (url) {
            const callback = function () {
                if (typeof url !== 'undefined') {
                    window.location = url;
                }
            };
            window.gtag('event', 'conversion', {
                'send_to': 'AW-11094216147/Zs-MCLvOmq8ZENObkaop',
                'transaction_id': '', // Provide a transaction ID if available
                'event_callback': callback
            });
            return false;
        };
    }

    submitted = async (lang) => {
        if (this.props.from && this.props.to && this.props.date && this.props.time) {
            this.setState({ loading: true }, async () => {
                // Perform conversion tracking right before navigation
                window.gtag_report_conversion(); // Call this without a URL unless you need redirection

                // Navigate to the booking page
                this.props.router.navigate(`/${lang}/${translations[lang].pages.booking}/${this.props.from.replace(/\s+/g, '')}/${this.props.to.replace(/\s+/g, '')}/${this.props.date.toLocaleDateString('en').replace(/\//g, "-")}/${this.props.time.toLocaleTimeString('en').replace(/\//g, "-")}?tab=${this.tabs[this.props.index]}`);
            });
        } else {
            this.setState({loading: false});
            this.props.buildError(<ul className="alert-box">
                <li className='information'><img src={require('../../images/icon/alert.png')} alt=""/>Please fill all fields<span><img
                    src={require('../../images/icon/delete.png')} alt=""/></span></li>
            </ul>);
        }
    };

    render() {
        if (this.state.loading) {
            return (<Loader/>);
        }

        return (
            <LanguageContext.Consumer>
                {({ language }) => (
                    <div className="submit_button" onClick={() => this.submitted(language)}>
                        <span className="register_now">{translations[language].reserved}<img
                            src={require('../../images/icon/arrow-white.png')} alt=""/></span>
                    </div>
                )}
            </LanguageContext.Consumer>
        )
    }
}

export default withRouter(SubmitButton);
SubmitButton.contextType = LanguageContext;
