import React from 'react';
import {
    Navigate,
    Routes,
    Route,
} from "react-router-dom";
import Header from './components/general/Header'
import Footer from './components/general/Footer'
import Home from './components/pages/Home'
import './css/bootstrap.min.css'
import './css/style.css'
import './css/responsive.css'
import "react-datepicker/dist/react-datepicker.css"
import './css/chat.css'
import Booking from "./components/pages/Booking";
import Register from "./components/pages/Register";
import Success from "./components/pages/Success";
import Logout from "./components/pages/Logout";
import LoginForm from "./components/login/LoginForm";
import ContactUs from "./components/pages/ContactUs";
import Quote from "./components/pages/Quote";
import Faq from "./components/pages/Faq";
import NotFound from "./components/pages/NotFound";
import {LanguageContext, languages} from './context/LanguageContext';
import Cookies from 'universal-cookie';
import ForgotPassword from "./components/pages/ForgotPassword";
import ApiClient from "./network/ApiClient";
import {UserContext} from "./context/UserContext";
import MyReservation from "./components/pages/MyReservation";
import Blog from "./components/pages/Blog";
import BlogSingle from "./components/pages/BlogSingle";
import {TransitionGroup, CSSTransition} from "react-transition-group";
import Loader from "./components/general/Loader";
import {translations} from "./translation/Translations";
import ExcursionsSingle from "./components/excursion/ExcursionSingle";
import ScrollToTop from "./ScrollToTop";
import Modal from "./components/general/Modal";
import Failure from "./components/pages/Failure";
import ErrorBoundary from "./components/errorBoundary/ErrorBoundary";
import {withRouter} from "./router/ComponentWithRouterProp";

class App extends React.Component {
    chatClick = null;
    chatEvent = null;

    constructor(props) {
        super(props);
        const cookies = new Cookies();
        this.toggleLanguage = (language) => {
            this.setState({language});
            cookies.set('hl', language, {path: '/'});
        };
        this.setUser = (user) => {
            this.setState({user});
            cookies.set('userId', user ? user.id : '', {path: '/'});
        };
        this.state = {
            language: cookies.get('hl') ? cookies.get('hl') : (('en' === navigator.language.substring(0, 2) || 'hr' === navigator.language.substring(0, 2)) ? navigator.language.substring(0, 2) : languages.en),
            toggleLanguage: this.toggleLanguage,
            setUser: this.setUser,
            locations: [],
            fetchingLocations: false,
            fetchingUser: false,
            fromId: null,
            toId: null,
            user: null,
            excursions: [],
            showHeader: true,
            setLoader: false,
            isChatOpened: false,
            unreadMessages: 0,
            socketOpened: false,
            askToLoadMessages: false,
            canAskToLoadMessages: true,
        };
    }

    async componentDidMount() {/*
        const chatTriggers = document.getElementsByClassName("rcw-launcher");
        Array.from(chatTriggers).forEach((element) => {
            this.chatEvent = element.addEventListener('click', () => {
                this.setState({unreadMessages: 0});
            });
        });
        const apiClient = new ApiClient();
        const cookies = new Cookies();
        this.cookies = new Cookies();
        const userId = ('string' === typeof cookies.get('userId') && cookies.get('userId') && 'null' !== cookies.get('userId')) ? cookies.get('userId') : null;

        if (!this.state.user && userId) {
            this.setState({fetchingUser: true}, () => {
                apiClient.getUserById(cookies.get('userId')).then(
                    (result) => {
                        this.setState({user: result.data, fetchingUser: false}, () => {
                            let clientId = null;
                            if (this.state.user) {
                                clientId = userId;
                                this.startSocket(clientId);
                            } else {
                                clientId = uuid();
                                cookies.set('clientId', clientId, {path: '/'});
                            }
                        });
                    },
                    (error) => {
                        this.setState({fetchingUser: false});
                    }
                );
            });
        } else {
            let clientId = null;
            if (cookies.get('clientId')) {
                clientId = cookies.get('clientId');
            } else {
                clientId = uuid();
                this.setState({canAskToLoadMessages: false});
                cookies.set('clientId', clientId, {path: '/'});
            }
            this.startSocket(clientId);
        }

        const chatWrappers = document.getElementsByClassName("rcw-widget-container");
        for (let i = 0; i < chatWrappers.length; i++) {
            this.chatClick = chatWrappers[i].addEventListener('click', () => {
                this.checkToLoadMessages()
            }, false);
        }
    */
        window.document.documentElement.lang = this.state.language;
    }


    componentWillUnmount() {
        if (this.chatClick) {
            this.chatClick = null;
        }
        if (this.chatEvent) {
            this.chatEvent = null;
        }
    }

    checkToLoadMessages = () => {
        this.setState({askToLoadMessages: true});
    };

    loadMessages = () => {
        const apiClient = new ApiClient();
        let clientId = this.cookies.get('userId');
        if (!clientId || 'undefined' === clientId || 'null' === clientId) {
            clientId = this.cookies.get('clientId')
        }
        apiClient.getClientMessages(clientId).then(
            (result) => {
                if (result.data) {
                    this.preFillMessages(result.data);
                    this.chatClick = null;
                }
            },
            (error) => {
            }
        );
    };

    getExcursions = () => {
        const apiClient = new ApiClient();
        apiClient.getExcursions().then(
            (result) => {
                this.setState({excursions: result});
            },
            (error) => {
                console.log(error);
            }
        );
    };


    setFromId = (fromId) => {
        this.setState({fromId});
    };

    setToId = (toId) => {
        this.setState({toId});
    };

    hideHeader = () => {
        this.setState({showHeader: false});
    };

    changeLoader = (status) => {
        this.setState({setLoader: status});
    };

    createChatMessageObject = (chatMessage) => {
        const clientId = this.state.user ? this.state.user.id : this.cookies.get('clientId');
        return {
            type: 'message',
            attributes: {
                message: chatMessage,
                sentBy: clientId,
                clientId: clientId,
                createdAt: new Date().toISOString().slice(0, 19).replace('T', ' ')
            },
            relationships: {
                user: {
                    data: this.state.user ? {
                        type: "user",
                        id: this.state.user.id
                    } : null
                }
            }
        };
    };


    render() {
        if (this.state.setLoader) {
            return (<Loader/>);
        }

        return (
            <LanguageContext.Provider value={this.state}>
                <UserContext.Provider value={this.state}>
                    <ErrorBoundary>
                        <ScrollToTop>
                            <div className="layout-theme">
                                {this.state.showHeader && <Header/>}
                                <TransitionGroup className="transition-group">
                                    <CSSTransition
                                        key={this.props.router.navigate.key}
                                        timeout={{enter: 200, exit: 100}}
                                        classNames={'fade'}
                                    >
                                        <section className="route-section">
                                            {this.state.askToLoadMessages && this.state.canAskToLoadMessages &&
                                                <Modal text={'Počmi novi ili loadaj stari razgovor ?'}
                                                       close={() => {
                                                           this.setState({
                                                               askToLoadMessages: false,
                                                               canAskToLoadMessages: false
                                                           });
                                                           this.chatClick = null;
                                                       }}
                                                       confirm={() => {
                                                           this.loadMessages();
                                                           this.setState({
                                                               askToLoadMessages: false,
                                                               canAskToLoadMessages: false
                                                           });
                                                       }}
                                                />}
                                            <Routes>
                                                <Route path="/hr/izlet/nacionalni-park-slapovi-krke"
                                                       element={<ExcursionsSingle
                                                           full={('krka_full.png')}
                                                           image={translations.en.excursion.img1}
                                                           title={translations.hr.excursion.title1}
                                                           author={translations.hr.excursion.author1}
                                                           date={translations.hr.excursion.date1}
                                                           body_main1={translations.hr.excursion.body_main1}
                                                           main_highlight={translations.hr.excursion.body_highlight}
                                                           body_main_footer={translations.hr.excursion.body_main2}
                                                           index={1}
                                                       />}
                                                />
                                                <Route path="/hr/izlet/nacionalni-park-plitvicka-jezera"
                                                       element={<ExcursionsSingle
                                                           full={('plitvice_full.webp')}
                                                           image={translations.en.excursion.img2}
                                                           title={translations.hr.excursion.title2}
                                                           author={translations.hr.excursion.author2}
                                                           date={translations.hr.excursion.date2}
                                                           body_main1={translations.hr.excursion.body_main21}
                                                           main_highlight={translations.hr.excursion.body_highlight2}
                                                           body_main_footer={translations.hr.excursion.body_main22}
                                                           index={2}
                                                       />}
                                                />
                                                <Route
                                                    path="/hr/izlet/split-i-trogir-privatni-izlet"
                                                    element={<ExcursionsSingle
                                                        full={('trogir_full.webp')}
                                                        image={translations.en.excursion.img3}
                                                        title={translations.hr.excursion.title3}
                                                        author={translations.hr.excursion.author3}
                                                        date={translations.hr.excursion.date3}
                                                        body_main1={translations.hr.excursion.body_main31}
                                                        main_highlight={translations.hr.excursion.body_highlight3}
                                                        body_main_footer={translations.hr.excursion.body_main23}
                                                        index={3}
                                                    />}
                                                />
                                                <Route
                                                    path="/en/excursion/national-park-krka-waterfalls"
                                                    element={<ExcursionsSingle
                                                        full={'krka_full.webp'}
                                                        image={translations.en.excursion.img1}
                                                        title={translations.en.excursion.title1}
                                                        author={translations.en.excursion.author1}
                                                        date={translations.en.excursion.date1}
                                                        body_main1={translations.en.excursion.body_main1}
                                                        main_highlight={translations.en.excursion.body_highlight}
                                                        body_main_footer={translations.en.excursion.body_main2}
                                                        index={1}
                                                    />}
                                                />
                                                <Route path="/en/excursion/national-park-plitvice-lakes"
                                                       element={<ExcursionsSingle
                                                           full={('plitvice_full.webp')}
                                                           image={translations.en.excursion.img2}
                                                           title={translations.en.excursion.title2}
                                                           author={translations.en.excursion.author2}
                                                           date={translations.en.excursion.date2}
                                                           body_main1={translations.en.excursion.body_main21}
                                                           main_highlight={translations.en.excursion.body_highlight2}
                                                           body_main_footer={translations.en.excursion.body_main22}
                                                           index={2}
                                                       />}
                                                />
                                                <Route path="/en/excursion/split-and-trogir-private-tour"
                                                       element={<ExcursionsSingle
                                                           full={('trogir_full.webp')}
                                                           image={translations.en.excursion.img3}
                                                           title={translations.en.excursion.title3}
                                                           author={translations.en.excursion.author3}
                                                           date={translations.en.excursion.date3}
                                                           body_main1={translations.en.excursion.body_main31}
                                                           main_highlight={translations.en.excursion.body_highlight3}
                                                           body_main_footer={translations.en.excursion.body_main23}
                                                           index={3}
                                                       />}
                                                />
                                                <Route path="/hr/blog/zasto-je-vazno-rezervirati-prijevoz-na-vrijeme"
                                                       element={<BlogSingle
                                                           title={translations.hr.blogSingle.title1}
                                                           author={translations.hr.blog.author1}
                                                           date={translations.hr.blog.date1}
                                                           body_main1={translations.hr.blogSingle.body_main1}
                                                           main_highlight={translations.hr.blogSingle.body_highlight}
                                                           body_main_footer={translations.hr.blogSingle.body_main2}
                                                           index={1}
                                                       />}
                                                />
                                                <Route
                                                    path="/hr/blog/prijevoz-gostiju-s-svih-aerodroma-u-hrvatskoj"
                                                    element={<BlogSingle
                                                        title={translations.hr.blogSingle.title2}
                                                        author={translations.hr.blog.author2}
                                                        date={translations.hr.blog.date2}
                                                        body_main1={translations.hr.blogSingle.body_main21}
                                                        main_highlight={translations.hr.blogSingle.body_highlight2}
                                                        body_main_footer={translations.hr.blogSingle.body_main22}
                                                        index={2}
                                                    />}
                                                />

                                                <Route
                                                    path="/hr/blog/kako-rezervirati-transfer-sa-aerodroma-u-hrvatskoj"
                                                    element={<BlogSingle
                                                        title={translations.hr.blogSingle.title3}
                                                        author={translations.hr.blog.author3}
                                                        date={translations.hr.blog.date3}
                                                        body_main1={translations.hr.blogSingle.body_main31}
                                                        main_highlight={translations.hr.blogSingle.body_highlight3}
                                                        body_main_footer={translations.hr.blogSingle.body_main23}
                                                        index={3}
                                                    />}
                                                />

                                                <Route
                                                    path="/en/blog/why-is-it-important-to-book-airport-transfer-in-croatia-on-time"
                                                    element={<BlogSingle title={translations.en.blogSingle.title1}
                                                                         author={translations.en.blog.author1}
                                                                         date={translations.en.blog.date1}
                                                                         body_main1={translations.en.blogSingle.body_main1}
                                                                         main_highlight={translations.en.blogSingle.body_highlight}
                                                                         body_main_footer={translations.en.blogSingle.body_main2}
                                                                         index={1}
                                                    />}
                                                />

                                                <Route
                                                    path="/en/blog/transfers-from-and-to-all-airports-in-croatia"
                                                    element={<BlogSingle
                                                        title={translations.en.blogSingle.title2}
                                                        author={translations.en.blog.author2}
                                                        date={translations.en.blog.date2}
                                                        body_main1={translations.en.blogSingle.body_main21}
                                                        main_highlight={translations.en.blogSingle.body_highlight2}
                                                        body_main_footer={translations.en.blogSingle.body_main22}
                                                        index={2}
                                                    />}
                                                />

                                                <Route path="/en/blog/how-to-book-a-transfer-in-croatia"
                                                       element={<BlogSingle
                                                           title={translations.en.blogSingle.title3}
                                                           author={translations.en.blog.author3}
                                                           date={translations.en.blog.date3}
                                                           body_main1={translations.en.blogSingle.body_main31}
                                                           main_highlight={translations.en.blogSingle.body_highlight3}
                                                           body_main_footer={translations.en.blogSingle.body_main23}
                                                           index={3}
                                                       />}
                                                />

                                                <Route path="/hr/blog"
                                                       element={<Blog/>}
                                                />
                                                <Route path="/en/blog"
                                                       element={<Blog/>}
                                                />
                                                <Route path="/hr/rezervacije"
                                                       element={<MyReservation/>}
                                                />
                                                <Route path="/en/reservations"
                                                       element={<MyReservation/>}
                                                />
                                                <Route path="/hr/zaboravljena-lozinka"
                                                       element={<ForgotPassword/>}
                                                />
                                                <Route path="/hr/forgot-password"
                                                       element={<ForgotPassword/>}
                                                />
                                                <Route path="/hr/cesto-postavljanja-pitanja"
                                                       element={<Faq/>}
                                                />
                                                <Route path="/en/faq"
                                                       element={<Faq/>}
                                                />
                                                <Route path="/hr/ponuda"
                                                       element={<Quote/>}
                                                />
                                                <Route path="/en/quote"
                                                       element={<Quote/>}
                                                />
                                                <Route path="/hr/kontaktiraj-nas"
                                                       element={<ContactUs/>}
                                                />
                                                <Route path="/en/contact-us"
                                                       element={<ContactUs/>}
                                                />
                                                <Route path="/hr/uspjesno"
                                                       element={<Success/>}
                                                />
                                                <Route path="/en/success"
                                                       element={<Success/>}
                                                />
                                                <Route path="/hr/odjava"
                                                       element={<Logout/>}
                                                />
                                                <Route path="/en/logout"
                                                       element={<Logout/>}
                                                />
                                                <Route path="/hr/registracija"
                                                       element={<Register includeTabs={false} setUser={this.setUser}/>}
                                                />
                                                <Route path="/en/register"
                                                       element={<Register includeTabs={false} setUser={this.setUser}/>}
                                                />
                                                <Route path="/en/login"
                                                       element={<LoginForm includeTabs={false} setUser={this.setUser}/>}
                                                />
                                                <Route path="/hr/prijava"
                                                       element={<LoginForm includeTabs={false} setUser={this.setUser}/>}
                                                />
                                                <Route path="/hr/rezervacija/:from/:to/:date/:time"
                                                       element={<Booking
                                                           locations={this.state.locations}
                                                           fetchingLocations={this.state.fetchingLocations}
                                                           fromId={this.state.fromId}
                                                           toId={this.state.toId}
                                                           setUser={this.setUser}
                                                           changeLoader={this.changeLoader}
                                                       />}
                                                />
                                                <Route path="/hr/rezervacija/:from/:to/:date"
                                                       element={<Booking
                                                           locations={this.state.locations}
                                                           fetchingLocations={this.state.fetchingLocations}
                                                           fromId={this.state.fromId}
                                                           toId={this.state.toId}
                                                           setUser={this.setUser}
                                                           changeLoader={this.changeLoader}
                                                       />}
                                                />
                                                <Route path="/hr/rezervacija/:from/:to"
                                                       element={<Booking
                                                           locations={this.state.locations}
                                                           fetchingLocations={this.state.fetchingLocations}
                                                           fromId={this.state.fromId}
                                                           toId={this.state.toId}
                                                           setUser={this.setUser}
                                                           changeLoader={this.changeLoader}
                                                       />}
                                                />
                                                <Route path="/en/failure"
                                                       element={<Failure/>}
                                                />
                                                <Route path="/en/booking/:from/:to/:date/:time"
                                                       element={<Booking
                                                           locations={this.state.locations}
                                                           fetchingLocations={this.state.fetchingLocations}
                                                           fromId={this.state.fromId}
                                                           toId={this.state.toId}
                                                           setUser={this.setUser}
                                                           changeLoader={this.changeLoader}
                                                       />}
                                                />
                                                <Route exact={true} path="/en"
                                                       element={<Home
                                                           locations={this.state.locations}
                                                           fetchingLocations={this.state.fetchingLocations}
                                                           setFromId={this.setFromId}
                                                           setToId={this.setToId}
                                                           excursions={this.state.excursions}
                                                           getExcursions={this.getExcursions}
                                                       />}
                                                />
                                                <Route exact={true} path="/hr"
                                                       element={<Home
                                                           locations={this.state.locations}
                                                           fetchingLocations={this.state.fetchingLocations}
                                                           setFromId={this.setFromId}
                                                           setToId={this.setToId}
                                                           excursions={this.state.excursions}
                                                           getExcursions={this.getExcursions}
                                                       />}
                                                />
                                                <Route exact={true} path="/"
                                                       element={<Navigate to={`/${this.state.language}`}/>}
                                                />
                                                <Route path="*"
                                                       element={<NotFound hideHeader={this.hideHeader}/>}
                                                />
                                            </Routes>
                                        </section>
                                    </CSSTransition>
                                </TransitionGroup>
                                <Footer/>
                            </div>
                        </ScrollToTop>
                    </ErrorBoundary>
                </UserContext.Provider>
            </LanguageContext.Provider>
        );
    }
}

export default withRouter(App);

