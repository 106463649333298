import React from "react";
import ContactForm from "../contactus/ContactForm";


class Quote extends React.Component {
    render() {
        return(
            <ContactForm />
        )
    }
}

export default Quote