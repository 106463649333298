import React from "react";
import {withRouter} from "../../router/ComponentWithRouterProp";
import {UserContext} from "../../context/UserContext";

class Logout extends React.Component {
    componentDidMount() {
        this.context.setUser(null);
        this.props.router.navigate('/');
    }

    render() {
        return (<React.Fragment></React.Fragment>);
    }
}

export default withRouter(Logout);
Logout.contextType = UserContext;
