export const translations = {
    en: {
        transaction_failed:"Transaction failed, please try again later",
        copyright: "Copyright Croatia Airport Transfer © 2024. All Rights Reserved | Powered by <a href='https://www.trazim.hr'>Hrvatska freelance platforma</a>",
        loading:"Loading...",
        addExtraFeatures:"Extra Features",
        remove:"Remove",
        from:"From",
        to:"To",
        extraFeatures:"Add",
        returnTransfer:"Add",
        noReservations:"No reservations",
        login:"Login",
        continueWithout:"CONTINUE",
        ourFleet:"Our Fleet",
        aboutUs:"O nama",
        googlePlay:"Google Play",
        googlePlayText:"Available at",
        appStore:"App Store",
        appStoreText:"Available at",
        airportsWeCover:"Airports we cover in Croatia",
        login_header:"Login",
        logout_header:"Logout",
        reservations_header:"Reservations",
        register_header:"Register",
        onlineBooking:"Online Booking",
        quotee:"Get a Quote",
        home:"Home",
        contacttUs:"Contact Us",
        faqq:"FAQ",
        blogg:"Blog",
        cash:"I choose pay directly to driver",
        register:"REGISTER",
        terms:"Accept terms & conditions and the privacy policy",
        repeatPassword: "Retype Password",
        password:"Password",
        username:"info@croatia-airport-transfer.com",
        pickUp:"Pick Up Address",
        dropOff:"Drop off Address",
        timePlaceholder:"Time",
        fleetTitle:"Our Fleet",
        fleetSubtitle:"We also take custom orders and will help you acquire a specific vehicle",
        fleetOption1:"All",
        fleetOption2:"Van",
        fleetOption3:"Minibus",
        fleetOption4:"Minibus",
        selectedCar:"SELECTED CAR",
        address:"Pick Up Address",
        reservationDetails:"RESERVATION DETAILS",
        thankNotice:'Your reservation has been successfully placed and its under pending while our administrator review and confirm it.',
        thankNotice1:'You will get confirmation e-mail with reservation details and invoice.',
        thankYou:"THANK YOU",
        duration:"Duration",
        reserved:"Book Now",
        pickUpTime:"Choose Pickup",
        pickupDate:"Choose Pickup",
        datePlaceholder:"Date",
        pickUpPlaceholder:"Address, airport, hotel...",
        details:"Show Route Details",
        hide:"Hide",
        continue:"CONTINUE",
        select:"SELECT",
        currency:"€",
        transfers: "Transfer Service",
        private: "Chauffeur Service",
        airport: "Airport Pickup / Drop-off",
        services: "Our Services",
        servicesText: "Best services for your airport, chauffeur and city transfers.",
        airportCenter: "We monitor your flight until you land and driver arrive suitably, so if your flight is delayed or early our driver will meet you on time.",
        airportRight: "Your driver will meet you in the arrivals hall with a name-board. You will also have his mobile number. Your vehicle will also have complimentary wifi and water.",
        transfersRight: "Our drivers are the most valuable part of our transfer service. We invest more effort acquiring the best drivers because our clients deserve and expect excellence. ",
        transfersCenter: "Enjoy the comfort of our limousines and business vans. Prepare for your next  vacation or business meeting in peace. Relax and view the highlights of the city while traveling to your destination.",
        privateRight: "All of our chauffeurs understand and sign our confidentiality agreement; discretion is always a priority. If the passenger would like to talk or ask questions then the chauffeur will then happily respond.",
        privateCenter: "Our chauffeurs are experienced and refined in the art of chauffeuring, these gentleman's are the very finest in their field. They all have excellent topographical knowledge of destination in Croatia.",
        carClass: "CAR CLASS",
        options: "OPTIONS",
        loginBooking: "LOGIN",
        payment:"PAYMENT",
        checkout:"COMPLETED",
        vehicles: {
            premium: "Premium Car",
            premiumName:"Mercedes-Benz E-Class",
            premiumDescription: "A favorite pick for frequent travelers, comfortable and safe with lots of space for luggage makes it ideal up to 3 persons.",
            premiumPeople: "Max: 3",
            premiumBags: "Max: 3",
            business: "Business Car",
            businessName:"Mercedes-Benz S-Class",
            businessDescription: "An luxurious and stylish limousine for comfortable journeys. Settle back and enjoy the sumptuous ride while sightseeing local places on the way.",
            businessPeople: "Max: 3",
            businessBags: "Max: 3",
            van: "Van",
            vanName:"Mercedes-Benz Vito / V-Class",
            vanDescription: "Popular pick for airport transfers and it takes up to the 7 people. Very spacious vehicle providing lots of space and flexibility to work, entertain or relax in impeccable safety and comfort",
            vanPeople: "Max: 7",
            vanBags: "Max: 7",
            minibus: "Minibus",
            minibusName:"Mercedes-Benz Sprinter",
            minibusDescription: "Minibus with reclining luxury seats, panoramic windows and the usual Mercedes-Benz refinements is perfect for bigger groups traveling together or organized weddings etc. ",
            minibusPeople: "Max: 19",
            minibusBags: "Max: 19",
        },
        optionsPage: {
            passengers:"Number Of Passengers",
            bags:"Number Of Bags",
            pickup:"Pick up address",
            dropoff:"Drop off address",
            firstName:"First Name",
            lastName:"Last Name",
            email:"Email Address",
            phone:"Phone Number",
            additional:"Additional Information",
            pickupTimeReturn: "Return pick up time",
            pickUpDateReturn: "Return pick up date",
        },
        contactUs: {
            mainTitle:"Contact Us",
            addressTitle:"Address",
            address:" Krbavska ul. 10, 21000, Split",
            email:"info@croatia-airport-transfer.com",
            phone:"+385995421487",
            workHours:"Work Hours",
            workHoursText:"Monday - Sunday: 24h",
            followUs:"Follow Us",
            formTitle:"Fill out contact form",
            formFirstName:"First Name",
            formEmailTitle:"E-mail",
            formPhone:"Phone",
            formDescription:"Description",
            formSubmit:"Send",
            messagePlaceholder:"Your message",
            fillAllFields: "Please fill all fields",
            formSent: "Message sent. We'll contact you as soon as possible.",
            sendFailed: "Something went wrong, please try again.",
        },
        banner: {
            title:"Tailor Made",
            text:"Tailor made is most popular services in Croatia (night parties, weddings, casinos, birthdays, etc.) we also take you to anywhere you want to go.",
            phone:"Call Now: +(385)99-542-1487",
            buttonText:"Book Now",
        },
        howItWorks: {
            title:"How It Works",
            subTitle:"Our booking process is simple and efficient",
            step1:"1 - Book Via Booking Form",
            step1text:"Enter your pickup & drop-off location and time of pickup/drop-off",
            step2:"2 - Choose Your Vehicle",
            step2text:"From list of vehicles pick the most suitable for your needs.",
            step3:"3 - Receive Confirmation E-mail",
            step3text:"After successful booking you will receive confirmation mail, day before of your booking you will receive e-mail with driver contact details.",
        },
        whyChooseUs: {
            title:"Why Choose Us",
            subTitle:"Croatia Airport Transfer employees are English speaking drivers that have lots of local knowledge, feel free to ask them for any guide, tips or information's you may need. Years of experience, professional drivers, high quality comfortable vehicles are only one of reasons why dozens of thousands tourists travelers chose our transfer taxi service in Croatia.",
            step1:"Easy Online Booking",
            step1text:"Book service in few easy steps using our most secured booking system",
            step2:"Professional Drivers",
            step2text:"Our professional drivers with many years of experience will make your visit to Croatia enjoyable and stress free",
            step3:"Variety of Car Brands",
            step3text:"Choose from fleet list car / van / minibus which suits your needs",
            step4:"Online Payment",
            step4text:"Pay deposit for your transfer using our payment gateway secured bit 2048-bit encrypt",

        },
        faq: {
            title1: "How do you book transfer?",
            body1: "You can make a reservation via our website https://www.croatia-airport-transfer.com and provide : Pick-up, drop-off, number of people and date. Then choose your vehicle type the price will be shown for them. If the destination you would like to travel to is not listed on our website, you can contact us via phone or email and we will provide you a quote for the reservation.",
            title2: "Does all reservations have to be prior to the date?",
            body2: "We recommend making a reservation prior to your date of travel, when you make a booking we put this in our booking system and book a vehicle for you. We can make last minute reservations, subject to availability for your chosen date and transfer route.",
            title3: "What vehicles are available?",
            body3: "We have available standard cars, luxury cars, passenger vans (5 people), passenger vans (8 people), minibus (14 people). If you require another vehicle type, we are able to organize these on request.",
            title4: "How do I locate my driver?",
            body4: "The driver will pick you up from your departure / pickup address that you provide in your reservation. If your arrival is at airport the driver will be waiting at the arrivals holding a tablet with your name on it. In case you are not able to establish contact with our driver at the agreed place and agreed time, please contact our call-center as soon as possible on +385 99 / 542 1487, where we will provide you further instructions. Keep note of our number!",
            title5: "What is my travel time?",
            body5:"We can provide you the journey time however the journey time can change due different factors. This could be traffic in high season, road works etc. The driver will let you know if there is going to be any major delays on your journey.",
            title6:"What does pay only deposit means ?",
            body6:"When you place an order of transfer from / to airport you will pay only deposit fee, after the drive is done you will pay full amount minus the deposit fee you paid during the booking.",
            title7:"Is it safe to pay using our website ?",
            body7:"Our site is secured by 2048 -bit encryption and Paypal is our provider of money transactions which makes our site 100% safe for ALL online transactions.",
        },
        blog: {
            title1:"Why is it important to book airport transfer in Croatia on time?",
            img1:"5",
            author1:"Admin",
            date1:"February 9, 2018",
            title2:"Transfers from and to all airports in Croatia",
            img2:"5",
            author2:"Admin",
            date2:"February 15, 2018",
            title3:"How to book a transfer in Croatia?",
            img3:"5",
            author3:"Admin",
            date3:"February 9, 2018",

        },
        blogSingle: {
            title1:"Why is it important to book airport transfer in Croatia on time?",
            body_main1:"This is one of the most wonderful places for enjoying summer holidays with friends and family. Croatia serves millions of tourists from different corners of the world. The top destinations stay crowded in all seasons throughout the year. The city tourism is mind blowing as all amenities are easier to access and all destinations are loaded with stunning natural beauty.If you are planning your tour first time to Croatia, you might be worried about taxi & transfer services.This is the most important matter for visitors as no one can spend time standing outside the airport after a long hour tiring flight. But the great news is that when you are planning your visit to Croatia, there is no need to worry about airport transfer services. Professionals at Croatia Airport Transfer are always ready to assist you with all travel needs in the city. The most budget-friendly transportation service in the city and no hidden fees are involved in your bookings.",
            body_highlight:"Are you ready to explore the wonderful locations of Croatia with family or friends? It is right time to book a luxurious yet budget-friendly airport transfer service for full comfort. Professionals at Croatia Airport Transfer are ready to serve you 24×7 so that you can enjoy reliable and trustworthy travel experience in the city.",
            body_main2:"Travelers can even book their taxis in advance to ensure timely pickup at the airport. If the flight is delayed, our driver will for you as we keep on tracking client flight. In case if you have to cancel your visit due to some unavoidable circumstances, you can cancel the taxi service also with 100% refund right before 5 days of your transfer. Croatia Airport Transfer have high-quality vehicles and professional drivers that can ensure your full comfort for your journey. You can book a standard vehicle, maxi-vans, mini-vans as well as buses for group transfers in the city. The great news is that you can book a private transfer with Croatia Airport Transfer service providers. Whether you are on a business tour or an adventure tour; company ensures you timely pick up and drop service during your visit to Croatia. Our vehicles are not shared !",
            author1:"Admin",
            date1:"February 9, 2018",
            title2:"Transfers from and to all airports in Croatia",
            body_main21:"Country known for its awesome natural beauty and it serves millions of tourists every year. At the same time, it is one of the most potential business hubs so many professionals keep on scheduling their visits to this location. Whether you are going to visit Croatia for the very first time or it is a place for your routine business meetings, you may often need to travel from airport or to airport in Croatia. In order to make your visits comfortable, it is important to book a trustworthy and reliable taxi service in the country.Croatia Airport Transfer service providers are always ready to provide satisfactory transfer services at all airport terminals of the city. You can book your taxi in advance to ensure on-time pick up from Zadar airport and the service providers will drop you at your hotel with full comfort. Those who are planning to land at Dubrovnik or Zagreb airport can also book their taxi in advance.",
            body_highlight2:"If you are planning to visit the historical places of the city including the ancient Roman buildings, prefer to book your flight to Pula airport as it will help you to reach all wonderful destinations with a short drive on road. The well-trained drivers at airport transfer services know shortest routes to all stunning tourist places of the city. They can help you to reach your destination without wasting much time in traffic.",
            body_main22:"If you want to take your kids to a beach holiday in upcoming summer season; Lošinj is the most wonderful destination. While booking your flight to this destination, prefer to make advance booking for a taxi as well. As soon as you will reach Lošinj, a well-experienced driver will be waiting for you with a luxurious car to take you for your beach adventure. After enjoying at the beach, you can enjoy a comfortable ride in a taxi to Rijeka and Split as well. Both these tourist destinations will create lots of beautiful memories for your adventure tour. Never forget to visit Brac Island and its seaside attractions. The professional Croatia Airport Transfer service providers will help you to take your flight back to your hometown from Brac airport.",
            author2:"Admin",
            date2:"February 15, 2018",
            title3:"How to book a transfer in Croatia?",
            body_main31:"This is one of the most wonderful places for enjoying summer holidays with friends and family. Croatia serves millions of tourists from different corners of the world. The top destinations stay crowded in all seasons throughout the year. The city tourism is mind blowing as all amenities are easier to access and all destinations are loaded with stunning natural beauty.If you are planning your tour first time to Croatia, you might be worried about taxi & transfer services.",
            body_highlight3:"This is the most important matter for visitors as no one can spend time standing outside the airport after a long hour tiring flight. But the great news is that when you are planning your visit to Croatia, there is no need to worry about airport transfer services.",
            body_main23:"The most budget-friendly transportation service in the city and no hidden fees are involved in your bookings. Travelers can even book their taxis in advance to ensure timely pickup at the airport. If the flight is delayed, our driver will for you as we keep on tracking client flight . In case if you have to cancel your visit due to some unavoidable circumstances, you can cancel the taxi service also with 100% refund right before 5 days of your transfer. Croatia Airport Transfer have high-quality vehicles and professional drivers that can ensure your full comfort for your journey. You can book a standard vehicle, maxi-vans, mini-vans as well as buses for group transfers in the city. The great news is that you can book a private transfer with Croatia Airport Transfer service providers.Whether you are on a business tour or an adventure tour; company ensures you timely pick up and drop service during your visit to Croatia. Our vehicles are not shared ! Are you ready to explore the wonderful locations of Croatia with family or friends? It is right time to book a luxurious yet budget-friendly airport transfer service for full comfort. Professionals at Croatia Airport Transfer are ready to serve you 24×7 so that you can enjoy reliable and trustworthy travel experience in the city.",
            author3:"Admin",
            date3:"February 9, 2018",
            next:"Next Post",
            previous:"Previous Post",

        },
        pages: {
            login: "login",
            register:"register",
            contactUs:"contact-us",
            faq:"faq",
            blog:"blog",
            quote:"quote",
            booking:"booking",
            success:"success",
            logout:"logout",
            reservations:"reservations",
            home:"",
        },
        paymentForm: {
            cardHolderName:"Cardholder Name",
            cardNumber:"Card Number",
            expDate:"Expiration Date",
            cvv: "CVV",
            notice:"Please note: Your card will not be charged until reservation has been reviewed and confirmed by our team. Usually it takes 1-2 hours until you receive confirmation email and invoice.",
            please_note:"Please note:",
        },
        loginForm: {
            email:"E-mail",
            password:"Password",
            rememberMe:"Remember Me",
            lostPassword:"Lost Your Password?",
            login:"Login",
            or: "OR",
            quick:"You can log in quickly with your account.",
            facebook:"Connect with Facebook",
            twitter:"Connect with Twitter",
        },
        errors: {
            fetchTransfer:"There was error processing your reservation please contact admin at info@croatia-airport-transfer.com",
            selectCar:"Please select car before you can continue to next step.",
            fillInfo:"Please fill out all necessary information before you can continue to next step.",
            maxLuggage:"Your input has exceeded maximum luggage that can fit in selected vehicle.",
            maxPeople:"Your input has exceeded maximum people that can fit in selected vehicle.",
            transferPassed:"Transfer date has passed",
            transferCancelled:"Transfer has been canceled",
            transferCancelledError:"Transfer has not been cancelled some error has occured.",
            terms:"You must accept terms of service.",
            password:"Password do not match.",

        },
        myTable: {
            id:"ID",
            route:"Route",
            date:"Date",
            price:"Price",
            status:"Status",
            action:"Action",
        },
        excursion: {
            img1:"krka_1",
            img2:"plitvice",
            img3:"trogir",
            title:"Excursions",
            sub_text:"Explore some of the best tours & excursions in Croatia",
            title1:"National park Krka waterfalls private tour",
            body_main1:"National park Krka is nearest tourist destination if you are based in Split or southern parts of Croatia. Only 1 hour and 30 minutes driving from Split over the highway to the beautiful Krka waterfalls national park. At entrance of the national park you must buy tickets where prices varies from babies,children,adults and group discounts.",
            body_highlight:"The Skradinski Buk waterfall, is the longest waterfall on the Krka River, and it is one of the best known natural beauties of Croatia.",
            body_main2:"When you arrive at Krka waterfalls you have lots of content to check out including: Skradinski buk, Visovac, Oziđana pećina, Roški slap, Krka monastery, Manojlovac waterfall.. We recommend visiting Visovac as its among the Croatia's most valuable natural and cultural values. After the Krka we take you to the Šibenik city which is among oldest citys in Croatia and have 2 UNESCO protected heritage, St.Jacob cathedral and St.Nicholas fortress.",
            author1:"Admin",
            date1:"February 9, 2018",
            title2:"National park Plitvice lakes private tour",
            body_main21:"We recommend early start from your pick up point depending on your city of residence. Sit back and enjoy a pleasant drive through the land of Croatia until you reach famous national park Plitvice Lakes. During the ride our driver will provide you with some interesting facts about the park and Croatia generally where you’ll pass by stunning landscapes along the way.",
            body_highlight2:"UNESCO protected natural heritage Plitvice Lakes are considered to be one of the most beautiful natural phenomena in this part of Europe.",
            body_main22:"Plitvice Lakes Croatia's are top 1 natural attraction and highlight of Croatia's heritage. Begin your adventure hiking through the forest, following the path on a 2.5-mile trail to (Upper Lakes). Hiking through the park you’ll be amazed by its turquoise lakes, beauty of the rushing waterfalls and the breathtaking views from the viewpoints. Plitvice Lakes are made of sixteen lakes that are connected by travertine waterfalls and surrounded by woodlands. Consist of seven different routes and four hiking trails as well as boardwalks that go over the lakes and waterways.",
            author2:"Admin",
            date2:"February 15, 2018",
            title3:"Discover Split and Trogir at private tour",
            body_main31:"Small town called Trogir founded on an island approximately 30km away from Split. Unique UNESCO World Heritage site definitely worth visiting when you're based in Dalmatia. At arrival you can hire a local tour guide who have lots of knowledge about this picturesque small town and will lead the way to the historic core of Trogir",
            body_highlight3:"Experience the magic of this cultural place and sightseeing heritage of Trogir",
            body_main23:"The old city of Trogir was built on a small island between Čiovo Island and the mainland which is older than the Diocletian’s Palace in Split and it is fully protected by UNESCO as a unique site.  Walking with the tour guide you will learn more about the city and its history and architectural solutions.",
            author3:"Admin",
            date3:"February 9, 2018",
            next:"Next Post",
            previous:"Previous Post",


        },
        reservations: {
            welcome: "Welcome",
            title: "Here you can see your reservations",
        },
        additional:{
            infant:"Infant seat (0-1 year)",
            child:"Child seat (1-5 year)",
            booster:"Booster seat (5-12 year)",
            bicycle:"Bicycle holder / space",
            wheelChair:"Wheelchair accessible",
            extra:"Extra stop in same town",
            returnTransfer:"Return Transfer",
            extraFeatures:"Extra Features",
        },
        slider: {
            text:"Redefining Luxury Transfers With Croatia Finest Chauffeurs",
            sub_text:"Quality Without Compromise",
        },
    },

    hr: {
        transaction_failed:"Transakcija nije uspjela, molimo pokušajte ponovno kasnije.",
        copyright: "Sva prava pridržana Croatia Airport Transfer © 2023. | Omogućeno od strane <a href='https://www.trazim.hr'>Trazim.hr</a>",
        loading:"Učitavanje...",
        from:"Od",
        to:"Do",
        addExtraFeatures:"Dodatne Usluge",
        remove:"Ukloni",
        extraFeatures:"Dodaj",
        returnTransfer:"Dodaj",
        noReservations:"Nema rezervacija",
        login:"Prijava",
        continueWithout:"NASTAVI",
        ourFleet:"Vozila",
        aboutUs:"O nama",
        googlePlay:"Google Trgovina",
        googlePlayText:"Dostupno u",
        appStore:"App Store",
        appStoreText:"Dostupno u",
        airportsWeCover:"Aerodromi koje pokrivamo u Hrvatskoj",
        login_header:"Prijavi se",
        logout_header:"Odjava",
        reservations_header:"Rezervacije",
        register_header:"Registriraj se",
        onlineBooking:"Online Rezervacija",
        quotee:"Zatraži Ponudu",
        home:"Početna",
        contacttUs:"Kontaktiraj Nas",
        faqq:"FAQ",
        blogg:"Blog",
        cash:"Plaćanje direktno vozaču",
        register:"REGISTRACIJA",
        terms:"Prihvatite uvjete & odredbe i politiku privatnosti",
        repeatPassword: "Ponovite Lozinku",
        password:"Lozinka",
        username:"info@croatia-airport-transfer.com",
        pickUp:"Adresa Polaska",
        dropOff:"Adresa Odredišta",
        timePlaceholder:"Vrijeme",
        fleetTitle:"Naša Vozila",
        fleetSubtitle:"Također preuzimamo narudžbe po vašoj mjeri i pomoći ćemo vam u nabavci određenog vozila",
        fleetOption1:"Svi",
        fleetOption2:"Kombi",
        fleetOption3:"Minibus",
        selectedCar:"ODABRANO VOZILO",
        address:"Adresa Polaska",
        reservationDetails:"DETALJI REZERVACIJE",
        thankNotice:'Vaša rezervacija je uspješno zaprimljena, molimo čekajte administratora da potvrdi vašu rezervaciju.',
        thankNotice1:'Primiti ćete e-mail s potvrdom rezervacije i račun.',
        thankYou:"HVALA",
        duration:"Trajanje",
        reserved:"Rezerviraj",
        change:"Izmijeni",
        pickUpTime:"Odaberite",
        pickupDate:"Odaberite",
        datePlaceholder:"Datum",
        pickUpPlaceholder:"Adresa, Aerodrom, Hotel...",
        details:"Pogledaj detalje",
        hide:"Sakrij",
        continue:"NASTAVI",
        select:"ODABERI",
        currency:"€",
        transfers: "Transferi",
        private: "Usluge Privatnog Vozača",
        airport: "Aerodrom Privatni Transfer",
        services: "Naše Usluge",
        servicesText: "Najbolje usluge za aerodrome, privatne vozače i prijevoz do grada",
        airportCenter: "Pratimo vaš let dok ne sletite i vozač stigne na odredište, tako da ako se vaš let odgodi ili urani naš vozač će vas dočekati na vrijeme",
        airportRight: "Vaš vozač dočekat će vas u dvorani za dolazak s natpisom s vašim imenom. Imat ćete i njegov broj mobitela za svaki slučaj. Vaše vozilo će imati besplatan wifi i vodu.",
        transfersCenter: "Uživajte u udobnosti naših limuzina i poslovnih kombija. U miru se pripremite za sljedeći odmor ili poslovni sastanak. Opustite se i pogledajte najvažnije dijelove grada dok putujete na svoje odredište.",
        transfersRight: "Naši vozači su najvrjedniji dio naše usluge prijevoza. Ulažemo više napora u nabavu najboljih vozača jer naši klijenti zaslužuju i očekuju izvrsnost.",
        privateRight: "Svi naši vozači razumiju i poštuju ugovor o povjerljivosti; diskrecija je uvijek prioritet. Ako putnik želi razgovarati ili postavljati pitanja, vozač će tada rado odgovoriti.",
        privateCenter: "Naši su vozači iskusni i predani umjetnosti vožnje, a ova gospoda su najfiniji na svom području. Svi imaju izvrsno poznavanje lokalnih odredišta u Hrvatskoj.",
        carClass: "VRSTA VOZILA",
        options: "OPCIJE",
        loginBooking: "PRIJAVA",
        payment:"PLAĆANJE",
        checkout:"DOVRŠENO",
        vehicles: {
            premium: "Premium Car",
            premiumName:"Mercedes-Benz E-Class",
            premiumDescription: "Omiljeni odabir za česte putnike, udoban i siguran s puno prostora za prtljagu čini ga idealnim za do 3 osobe. ",
            premiumPeople: "Max: 3",
            premiumBags: "Max: 3",
            business: "Business Car",
            businessName:"Mercedes-Benz S-Class",
            businessDescription: "Luksuzna i elegantna limuzina za ugodna putovanja. Odmaknite se i uživajte u raskošnoj vožnji razgledavajući lokalna mjesta na putu. ",
            businessPeople: "Max: 3",
            businessBags: "Max: 3",
            van: "Van",
            vanName:"Mercedes-Benz Vito / V-Class",
            vanDescription: "Popularni izbor za aerodromske transfere i prima do 7 osoba. Vrlo prostrano vozilo koje pruža puno prostora i fleksibilnosti za rad, zabavu ili opuštanje uz besprijekornu sigurnost i udobnost",
            vanPeople: "Max: 7",
            vanBags: "Max: 7",
            minibus: "Minibus",
            minibusName:"Mercedes-Benz Sprinter",
            minibusDescription: "Minibus s luksuznim sjedalima, panoramskim prozorima i uobičajenim dodacima Mercedes-Benza savršen je za veće grupe koje zajedno putuju ili organizirane grup, vjenčanja itd.",
            minibusPeople: "Max: 19",
            minibusBags: "Max: 19",
        },
        optionsPage: {
            passengers:"Broj Putnika",
            bags:"Broj Prtljage",
            pickup:"Pick up adresa",
            dropoff:"Drop off adresa",
            firstName:"Ime",
            lastName:"Prezime",
            email:"E-mail",
            phone:"Kontakt Broj",
            additional:"Dodatne Informacije",
            pickupTimeReturn: "Vrijeme povratnog transfera",
            pickUpDateReturn: "Datum povratnog transfera",
        },
        contactUs: {
            mainTitle:"Kontaktiraj nas",
            addressTitle:"Adresa",
            address:" Krbavska ul. 10, 21000, Split",
            email:"info@croatia-airport-transfer.com",
            phone:"+385995421487",
            workHours:"Radno Vrijeme",
            workHoursText:"Svaki dan: 24h",
            followUs:"Pratite Nas",
            formTitle:"Popunite kontakt formu",
            formFirstName:"Ime",
            formEmailTitle:"E-mail",
            formPhone:"Mob",
            formDescription:"Opis",
            formSubmit:"Pošalji",
            messagePlaceholder:"Vaša poruka",
            fillAllFields: "Molimo popunite sva polja",
            formSent: "Poruka poslana. Kontaktirat ćemo vas u najkraćem mogućem roku.",
            sendFailed: "Došlo je do pogreške, molim vas pokušajte ponovno.",
        },
        banner: {
            title:"Prilagođen Klijentu",
            text:"Zahtjev po mjeri klijenta je najpopularnija usluga u Hrvatskoj (noćne zabave, vjenčanja, kockarnice, rođendani, itd.). Također vas vodimo do mjesta gdje želite ići.",
            phone:"Nazovite: +(385)99-542-1487",
            buttonText:"Rezerviraj",
        },
        howItWorks: {
            title:"Kako rezervirati?",
            subTitle:"Naš sistem rezerviranja je brz i pouzdan",
            step1:"1 - Otvorite rezervacijski sistem",
            step1text:"Unesite vrijeme i datum polaska ili dolaska",
            step2:"2 - Odaberite Vozilo",
            step2text:"S liste vozila odaberite koje vam najviše odgovara.",
            step3:"3 - Primite mail potvrde",
            step3text:"Nakon uspješne rezervacije dobit ćete potvrdni mail, dan prije rezervacije dobit ćete e-mail s podacima za kontakt vozača.",
        },
        whyChooseUs: {
            title:"Zašto Odabrati Croatia Airport Transfer?",
            subTitle:"Djelatnici Croatia Airport Transfer-a pričaju engleski jezik i posjeduju puno lokalnog znanja, slobodno ih pitajte za svaki vodič, savjet ili informacije koje su vam možda potrebne. Dugogodišnje iskustvo, profesionalni vozači, visokokvalitetna udobna vozila samo su jedan od razloga zašto su deseci tisuća turista izabrali našu službu za transfer u Hrvatskoj.",
            step1:"Jednostavna online rezervacija",
            step1text:"Usluga rezerviranja u nekoliko jednostavnih koraka pomoću našeg najsigurnijeg rezervacijskog sustava",
            step2:"Profesionalni Vozači",
            step2text:"Naši profesionalni vozači s dugogodišnjim iskustvom učinit će vaš posjet Hrvatskom ugodnim i bez stresa",
            step3:"Raznolikost automobila",
            step3text:"Odaberite automobil / kombi / minibus s popisa flote koji odgovara vašim potrebama",
            step4:"Online plaćanje",
            step4text:"Plaćajte polog za vaš prijenos putem našeg gateway osiguranog bitnog 2048-bitnog enkripta",

        },
        faq: {
            title1: "Kako rezervirati prijevoz?",
            body1: "Rezervaciju možete izvršiti putem naše web stranice https://www.croatia-airport-transfer.com unosom : mjesto polaska, odredišta, broja osoba i datum. Zatim odaberite svoj tip vozila cijena će biti prikazana za njih. Ako odredište na koje želite putovati nije navedeno na našoj web stranici, možete nas kontaktirati telefonom ili e-poštom, a mi ćemo vam pružiti cijenu za rezervaciju.",
            title2: "Da li sve rezervacije moraju biti unaprijed rezervirane?",
            body2: "Preporučujemo da napravite rezervaciju prije vašeg datuma putovanja. Kada izvršite rezervaciju, to stavimo u naš sustav rezervacija i rezerviramo vozilo za vas. Možete rezervirati last minute rezervacije, ovisno o dostupnosti odabranog datuma i rute prijevoza.",
            title3:"Koja su vozila dostupna?",
            body3: "Na raspolaganju imamo standardne automobile, luksuzne automobile, putničke kombije (5 osoba), putničke kombije (8 osoba), minibus (14 osoba). Ako trebate drugi tip vozila, u mogućnosti smo ih organizirati na zahtjev.",
            title4: "Kako mogu pronaći svog vozača?",
            body4: "Vozač će vas preuzeti s adrese polaska / preuzimanja koju navedete u svojoj rezervaciji. Ako je vaš dolazak u zračnu luku, vozač će na dolasku čekati, držeći na sebi tablet sa vašim imenom. U slučaju da ne možete uspostaviti kontakt s našim vozačem na dogovoreno mjesto i dogovoreno vrijeme, molimo kontaktirajte naš telefonski centar što je prije moguće na broj +385 99 / 542 1487, gdje ćemo vam dati daljnje upute. Zapamtite naš broj!",
            title5: "Što ako se vrijeme polaska promijeni?",
            body5:"Možemo vam potvrditi vrijeme polaska, međutim vrijeme se može promijeniti zbog različitih faktora. To bi mogao biti promet u sezoni, radovi na cestama itd. Vozač će vas obavijestiti hoće li doći do većih kašnjenja na vašem putovanju. ?",
            title6:"Što znači platiti depozit?",
            body6:"Kada naručite transfer iz / do zračne luke platit ćete samo naknadu za polog, nakon što je vožnja izvršena, platit ćete puni iznos umanjen za naknadu koju ste uplatili tijekom bookinga.",
            title7: "Je li sigurno platiti pomoću naše web stranice ?",
            body7:"Naša web stranica je osigurana 2048-bitnom enkripcijom, a Paypal je naš pružatelj novčanih transakcija što našu web stranicu čini 100% sigurnom za SVE mrežne transakcije.",
        },
        blog: {
            title1:"Zašto je vazno rezervirati prijevoz na vrijeme?",
            img1:"5",
            author1:"Admin",
            date1:"February 9, 2018",
            title2:"Prijevoz gostiju s svih aerodroma u Hrvatskoj",
            img2:"5",
            author2:"Admin",
            date2:"February 15, 2018",
            title3:"Kako rezervirati transfer s Aerodroma?",
            img3:"5",
            author3:"Admin",
            date3:"February 9, 2018",

        },
        blogSingle: {
            title1:"Zašto je važno rezervirati prijevoz na vrijeme?",
            body_main1:"Hrvatska mjesto iz snova za uživanje u ljetnom odmoru s turistima iz različitih dijelova svijeta. Top destinacije ostaju u gužvama u svim godišnjim dobima tijekom cijele godine. Gradski turizam je prepun turista a svi sadržaji teški za pristup, a sva odredišta su prepuna nevjerojatnih prirodnih ljepota. Ako prvi put planirate putovanje u Hrvatsku, definitivno biste trebali biti zabrinuti zbog taksi i transfer usluga. To je najvažnija stvar za posjetitelje jer nitko ne želi provesti vrijeme stojeći i tražeći taksi  izvan zračne luke nakon dugotrajnog napornog leta. No, sjajna vijest je da kada planirate posjetiti Hrvatsku, nema potrebe brinuti o uslugama prijevoza iz zračne luke. Profesionalci u prijevozu do zračne luke Croatia uvijek su vam spremni pomoći u svim potrebama putovanja do i po gradu. Prijevozna usluga u gradu koja je najpovoljnija i bez skrivenih naknada uključena je u vaše rezervacije. ",
            body_highlight:"Jeste li spremni istražiti prekrasne lokacije Hrvatske s obitelji ili prijateljima? Pravo je vrijeme za udobnost i luzsuz na odmoru i rezervirati  povoljnu uslugu prijevoza iz zračne luke. Profesionalci u Croatia Airport Transfer spremni su vam biti na usluzi 24 × 7 tako da možete uživati ​​u putovanju do i po gradu. ",
            body_main2:"Putnici mogu čak rezervirati svoje privatne vozače unaprijed kako bi osigurali pravovremeni prijevoz u zračnoj luci. Ako let kasni, vaš će vozač biti tu za vas dok mi pratimo vaš let.  Croatia Airport Transfer nudi visokokvalitetna vozila i profesionalne vozače koji vam mogu osigurati punu udobnost za vaše putovanje.  Sjajna vijest je da možete rezervirati privatni prijevoz kod pružatelja usluga Croatia Airport Transfer, bez obzira jeste li u poslovnoj ili avanturističkoj turneji; tvrtka osigurava kvalitetu usluge tijekom posjete Hrvatskoj. Naša vozila se ne dijele! ",
            author1:"Admin",
            date1:"February 9, 2018",
            title2:"Prijevoz gostiju s svih aerodroma u Hrvatskoj",
            body_main21:"Zemlja poznata po fenomenalnim prirodnim ljepotama koju svake godine posjete milijuni turista. U isto vrijeme, to je jedno od najprometnijih središta poslovanja, pa mnogi poslovni ljudi stalno planiraju svoje posjete ovom mjestu. Bez obzira da li ćete posjetiti Hrvatsku prvi put ili je to mjesto za vaše rutinske poslovne sastanke, možda ćete često trebati putovati iz zračne luke ili na aerodrom u Hrvatskoj. Da bi vam posjete bile udobne, važno je rezervirati pouzdanu i pouzdanu taksi uslugu u zemlji. Davatelji usluga transfera zračne luke u Hrvatskoj uvijek su spremni pružiti zadovoljavajuće usluge prijevoza na svim aerodromskim terminalima u gradu. Možete rezervirati svoj taksi unaprijed kako biste osigurali pravovremeni preuzimanje zračne luke Zadar i pružatelji usluga će vas u vašem hotelu dočekati s punom udobnošću. Oni koji planiraju slijetanje na zračnu luku Dubrovnik ili Zagreb, mogu unaprijed rezervirati i svoj taksi. ",
            body_highlight2:"Ako planirate posjetiti povijesna mjesta grada, uključujući i drevne rimske građevine, radije rezervirajte let do zračne luke Pula jer će vam to pomoći da od kratkih putovanja stignete do svih prekrasnih odredišta. Dobro obučeni vozači na aerodromskim transferima znaju najkraće rute do sva zadivljujuća turistička mjesta u gradu. Oni vam mogu pomoći da stignete na svoje odredište bez trošenja puno vremena u prometu. ",
            body_main22:"Ako želite voditi svoju djecu na odmor na plaži u nadolazećoj ljetnoj sezoni; Lošinj je najljepše odredište. Dok rezervirate let do ovog odredišta, radije rezervirajte taksi unaprijed. Čim stignete do Lošinja , iskusni vozač čekat će vas s luksuznim automobilom koji će vas povesti u avanturu na plaži. Nakon uživanja na plaži možete uživati ​​u udobnoj vožnji taksijem do Rijeke i Splita. Obje ove turističke destinacije stvorite puno lijepih uspomena za vaš avanturistički obilazak. Nikad ne zaboravite posjetiti otok Brač i njegove morske atrakcije. Profesionalni pružatelji usluga Croatia Airport Transfer pomoći će vam da se vratite na točku polaska. ",
            author2:"Admin",
            date2:"February 15, 2018",
            title3:"Kako rezervirati transfer s aerodroma?",
            body_main31:"Ovo je jedno od najljepših mjesta za uživanje u ljetnom odmoru s prijateljima i obitelji. Hrvatska dočekuje milijune turista iz različitih dijelova svijeta. Top destinacije ostaju u gužvama u svim godišnjim dobima tijekom cijele godine. Gradski turizam ne smeta jer su svi sadržaji lakši za pristup, a sva odredišta su prepuna nevjerojatnih prirodnih ljepota. Ako prvi put planirate turneju po Hrvatskoj, možda biste bili zabrinuti zbog taksi i transfer usluga od i do aerodroma. ",
            body_highlight3:"Ovo je najvažnija stvar za posjetitelje jer nitko ne želi provesti vrijeme stojeći izvan zračne luke nakon dugotrajnog napornog leta. No sjajna je vijest da kada planirate posjetiti Hrvatsku, ne trebate brinuti o uslugama prijevoza iz zračne luke. ",
            body_main23:"Prijevozne usluge u gradu s najboljim cijenama i bez skrivenih naknada uključuju se u vaše rezervacije. Putnici mogu čak rezervirati svoj prijevoz unaprijed kako bi osigurali pravovremeni prijevoz u zračnoj luci. Ako let kasni, naš će vozač biti spreman za vas dok pratimo vaš let i obaviještavamo o promjenama . U slučaju da morate otkazati posjet zbog nekih neizbježnih okolnosti, možete otkazati prijevoz uslugu i sa 100% povrata 5 dana prije od prijevoza. Croatia Airport Transfer nudi visokokvalitetna vozila i profesionalne vozače koji vam mogu osigurati punu udobnost za vaše putovanje. U gradu možete rezervirati standardno vozilo, kombije, mini-bus, kao i autobuse za grupne prijevoze. Sjajna vijest je da možete rezervirati privatni transfer kod pružatelja usluga usluge Croatia Airport Transfer. Bez obzira jeste li u poslovnoj ili avanturističkoj turneji; tvrtka osigurava pravodobno skupljane i odbacivanje na odredište tijekom posjete Hrvatskoj. Naša vozila se ne dijele s drugim gostima! Jeste li spremni istražiti prekrasne lokacije Hrvatske s obitelji ili prijateljima? Pravo je vrijeme za punu udobnost rezervirati luksuznu, a opet povoljnu uslugu prijevoza iz zračne luke. Profesionalci za prijevoz do zračne luke u Hrvatskoj spremni su vam poslužiti 24 × 7 kako biste mogli uživati ​​u pouzdanom i pouzdanom putovanju u gradu. ",
            author3:"Admin",
            date3:"February 9, 2018",
            next:"Naprijed",
            previous:"Nazad",

        },
        pages: {
            login: "prijava",
            register:"registracija",
            contactUs:"kontaktiraj-nas",
            faq:"cesto-postavljanja-pitanja",
            blog:"blog",
            quote:"ponuda",
            booking:"rezervacija",
            success:"uspjesno",
            logout:"odjava",
            reservations:"rezervacije",
            home:"",
        },
        paymentForm: {
            cardHolderName:"Ime",
            cardNumber:"Broj Kartice",
            expDate:"Datum Isteka",
            cvv: "CVV",
            notice:"Napominjemo: Vaša se kartica neće naplaćivati ​​dok naš tim ne pregleda i potvrdi rezervaciju. Obično traje 1-2 sata dok ne primite potvrdnu e-poštu i fakturu.",

        },
        loginForm: {
            email:"E-mail",
            password:"Lozinka",
            rememberMe:"Zapamti Me",
            lostPassword:"Zaboravljena Lozinka?",
            login:"Prijavi se",
            or: "ILI",
            quick:"Možete se brzo prijavite preko",
            facebook:"Poveži se s Facebook",
            twitter:"Poveži se s Twitter",
        },
        myTable: {
            id:"ID",
            route:"Ruta",
            date:"Datum",
            price:"Cijena",
            status:"Status",
            action:"Akcija",
        },
        excursion: {
            img1:"krka_1",
            img2:"plitvice",
            img3:"trogir",
            title:"Izleti",
            sub_text:"Istražite neke od najbolji izleta u Hrvatskoj",
            title1:"Nacionalni park Krka privatni izlet",
            body_main1:"Nacionalni park Krka je najbliže turističko odredište ako se nalazite u Splitu ili južnim dijelovima Hrvatske. Samo 1 sat i 30 minuta vožnje od Splita preko autoceste do prekrasnog nacionalnog parka Krka slapovi. Na ulazu u nacionalni park morate kupiti ulaznice u kojima cijene variraju od dječjih, dječjih, odraslih i grupnih popusta.",
            body_highlight:"Skradinski Buk najduži je vodopad rijeke Krke i jedna je od najpoznatijih prirodnih ljepota Hrvatske.",
            body_main2:"Kada stignete na slapove Krke, imate mnoštvo sadržaja koje možete progledati uključujući: Skradinski buk, Visovac, Oziđana pećina, Roški slap, manastir Krka, vodopad Manojlovac. Preporučujemo posjet Visovcu kao njegovom najvrijednijem prirodnom i kulturnom bogatstvu u Hrvatskoj. Nakon Krke vodimo vas do grada Šibenika koji je među najstarijim gradovima u Hrvatskoj i koji ima 2 zaštićene baštine UNESCO-a, katedralu svetog Jakova i tvrđavu sv. Nikole. ",
            author1:"Admin",
            date1:"Veljača 9, 2018",
            title2:"Nacionalni park Plitvice privatni izlet",
            body_main21:"Preporučujemo rani pokret s vašeg mjesta polaska, ovisno o gradu stanovanja. Sjednite i uživajte u ugodnoj vožnji kopnom Hrvatske dok ne dođete do poznatog nacionalnog parka Plitvička jezera. Tijekom vožnje naš će vam vozač pružiti nekoliko zanimljivih činjenica o parku i Hrvatskoj općenito gdje ćete prolaziti zadivljujućim krajolicima uz put. ",
            body_highlight2:"UNESCO-ova zaštićena prirodna baština. Plitvička jezera smatraju se jednim od najljepših prirodnih pojava u ovom dijelu Europe.",
            body_main22:"Plitvička jezera Hrvatska su top 1 prirodna atrakcija i vrhunac hrvatske baštine. Započnite vašu avanturističku šetnju šumom, slijedeći stazu 2,5 kilometra do (Gornja jezera). Šetajući parkom ostat ćete zadivljeni njegovim tirkiznim jezerima, ljepotom užurbanih slapova i prekrasnim pogledima s vidikovca. Plitvička jezera sačinjena su od šesnaest jezera koja su povezana travertinskim slapovima i okružena šumom. Sastoji se od sedam različitih ruta i četiri pješačke staze, kao i pločnika koja idu preko jezera i vodenih putova. ",
            author2:"Admin",
            date2:"Veljača 15, 2018",
            title3:"Split i Trogir privatni izlet",
            body_main31:"Mali grad Trogir osnovan je na otoku, oko 30 km udaljenom od Splita. Jedinstveno mjesto UNESCO-ve svjetske baštine definitivno vrijedi posjetiti kad ste sa sjedištem u Dalmaciji. Po dolasku možete unajmiti lokalnog vodiča koji ima puno znanja o ovom slikovitom gradiću i vodit će vas do povijesne jezgre Trogira ",
            body_highlight3: "Doživite čari ovog kulturnog mjesta i razgledavanje baštine Trogira",
            body_main23:"Stari grad Trogir izgrađen je na malom otoku između otoka Čiova i kopna koji je stariji od Dioklecijanove palače u Splitu i u potpunosti je zaštićen od strane UNESCO-a kao jedinstveno mjesto. Šetajući s vodičem saznat ćete više o gradu, njegovoj povijesti i arhitektonskim rješenjima. ",
            author3:"Admin",
            date3:"Veljača 9, 2018",
            next:"Sljedeći",
            previous:"Prethodni",
        },
        errors: {
            fetchTransfer:"Došlo je do pogreške prilikom rezervacije vašeg prijevoza, kontaktirajte administratora na info@croatia-airport-transfer.com",
            selectCar:"Molimo odaberite vozilo prije nego možete nastaviti na sljedeći korak.",
            fillInfo:"Molimo ispunite sve informacije prije nego možete nastaviti na sljedeći korak.",
            maxLuggage:"Vaš odabir prtljage je neispravan, više nego što vozilo može primiti.",
            maxPeople:"Vaš odabir broja putnika je neispravan, više nego što vozilo može primiti.",
            transferPassed:"Datum transfera je prošao.",
            transferCancelled:"Vaš transfer je otkazan.",
            transferCancelledError:"Vaš transfer nije otkazan dogodila se pogreška , pokušajte ponovno.",
            terms:"Morate prihvatiti uvjete.",
            password:"Lozinka se ne podudara.",

        },
        reservations: {
            welcome: "Dobrodošli",
            title: "Ovdje možete vijdeti svoje rezervacije",
        },

        additional:{
            infant:"Autosjedalica za novorođenčad (0-1 godina)",
            child:"Dječja autosjedalica (1-5 godina)",
            booster:"Booster autosjedalica (5-12 godina)",
            bicycle:"Bicikl",
            wheelChair:"Invalidska kolica",
            extra:"Dodatno stajanje u istom gradu",
            returnTransfer:"Povratni Transfer",
            extraFeatures:"Dodatne Značajke",
        },
        slider: {
            text:"Preoblikovanje luksuznih transfera s najfinijim vozačima u Hrvatskoj",
            sub_text:"Kvaliteta bez kompromisa",
        },

    },
};
